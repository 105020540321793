import React, { useEffect, useContext, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

import CustomAuth from "../components/custom-auth"
import { UserContext, AuthContext } from "../provider/usercontextprovider"
import { API, graphqlOperation } from "aws-amplify"
import { LoadMaskContext } from "../provider/loadmaskprovider"

const ReportDownloadPage = () => {
  const [userContext, setUserContext] = useContext(UserContext)
  const [authState, setAuthState] = useContext(AuthContext)
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [report, setReport] = useState({})
  const [failureMsg, setFailureMsg] = useState(null)

  const getReport = `
    query GetReport($id: ID!) {
      getReport(id: $id) {
        id,
        url,
        name
      }
    }
  `

  async function loadReport() {
    try {
      setIsLoading(true)
      const urlParams = new URLSearchParams(window.location.search)
      const downloadId = urlParams.get("downloadId")
      const result = await API.graphql(
        graphqlOperation(getReport, {
          id: downloadId,
        })
      )
      console.log(result)
      if (result.data.getReport) {
        setReport(result.data.getReport)
        window.open(result.data.getReport.url)
      } else {
        //show some type of error page?
        setFailureMsg('Report not found')
      }
    } catch(e){
      setFailureMsg('Failed to load report')
    }finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userContext.username) {
      loadReport()
    }
  }, [userContext.username])

  useEffect(() => {
    console.log(authState)
    if (authState == "signin") {
      setReport({})
      setFailureMsg(null)
    }
  }, [authState])

  return (
      <CustomAuth>
        <SEO title="Report Download" />

        <div>
          <div>
            <h1>Report Download</h1>
          </div>
          <div
              style={{
                fontSize: "14px",
              }}
            >

            {report.id && (
              <p>Downloading {report.name}</p>
            )}

            {failureMsg && (
              <p>Failed to retrieve report: {failureMsg}</p>
            )}

            {!report.id && !failureMsg && (
              <p>Fetching report</p>
            )}
          </div>
          
        </div>
      </CustomAuth>
  )
}
export default ReportDownloadPage
