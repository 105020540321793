import '@aws-amplify/ui-react/styles.css';
import React, { useEffect, useState, useContext } from "react"

import { Authenticator, AmplifySignUp, AmplifySignIn, CheckboxField, TextField } from '@aws-amplify/ui-react'
import { UserContext,AuthContext } from '../provider/usercontextprovider'
import { Auth, Hub } from 'aws-amplify'
import './auth.module.css'

const CustomAuth = (props) => {
  const [authState, setAuthState] = useContext(AuthContext);
  const [userContext, setUserContext] = useContext(UserContext);

  const HubListener = () => {
    Hub.listen('auth', data => {
      if(data.payload.event == 'signIn') {
        setUserContext(data.payload.data)
      } else if(data.payload.event == 'signOut') {
        setUserContext({username: null})
      }
    })
  }
  HubListener()

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    Auth.configure({clientMetadata:{inviteId:urlParams.get('inviteId')}});

    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => setUserContext(user))
    .catch(err => {
      setUserContext({username: null})
      Auth.signOut()
      console.log(err)
    });
  },[])
  
  return userContext.username == null ? (
    <Authenticator style = {{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      flexWrap: `wrap`,
      alignContent: `flex-start`
    }}
    services={{
      handleSignIn(formData){
        formData.username = formData.username.replace(/\s/g, '')
        return Auth.signIn(formData)
      }
    }}
    components={{
      SignUp: {
        FormFields(){
          return(
            <>
              <TextField name="given_name" placeholder="First Name" required={false}/>
              <TextField name="family_name" placeholder="Last Name" required={false}/>
              <TextField name="email" placeholder="Email" required={false} type = "email"/>
              <TextField name="phone_number" placeholder="Phone Number" required={false}/>
              <Authenticator.SignUp.FormFields/>
            </>
          )
        }
      }
    }}/>
  ): (props.children)
}

export default CustomAuth